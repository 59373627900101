

.Cards {
    /* Your card styles */
    position: absolute;
}

.triangle {
    /* Triangle styles */
    position: absolute;
    top: -12px;
    right: -108px;
    /* Adjust as needed */
    width: 0;
    rotate: 315deg;
    height: 0;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-bottom: 80px solid yellow;
    /* Red color; adjust as needed */
}

.approved-text {
    /* Text inside the triangle */
    position: absolute;
    top: 52px;

    transform: translate(-50%, -50%);
    /* White color; adjust as needed */
    font-size: 15px;
    /* Adjust font size */
}