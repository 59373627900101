.searchbarToggle input[type=checkbox] {
	height: 0;
	width: 0;
	visibility: hidden;
}

.searchbarToggle label {
	cursor: pointer;
	text-indent: -9999px;
	width: 60px;
	height: 30px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.summaryGraph .searchbarToggle label {
	width: 45px;
	height: 21px;
}

.summaryGraph .searchbarToggle label:after {
	width: 16px;
	height: 16px;
}

.summaryGraph .searchbarToggle label:after {
	top: 4px;
}

.searchbarToggle label:after {
	content: 'no ';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 22px;
	height: 22px;
	background: #343a40;
	border-radius: 40px;
	transition: 0.3s;
}

.searchbarToggle label:before {
	content: 'yes ';
}

.searchbarToggle input:checked+label {
	background: #f2b74e;
	content: 'yes ';
}

.searchbarToggle input:checked+label:after {
	left: calc(100% - 5px);
	content: 'no ';
	transform: translateX(-100%);
}

.searchbarToggle label:active:after {
	width: 70px;
}



/* body {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
} */